import { toArray } from "./toArray";
import { Operation } from "@nutrien/dxp-orchestrator-module";

// Permissions
const keyName = "userPermissions";
const adminKeyName = "isAdmin";
const userIdKey = "userId";

interface Permission {
  feature: string;
  attribute: string;
  operation: Operation;
}

interface SecurityGroup {
  securityGroupId: number;
  name: string;
  permissions: Permission[];
}

interface Entity {
  entityId: number; // Aggregate Group ID
  entityName: string;
  securityGroups: SecurityGroup[];
}

let userPermissionsByGroupId: AggregateGroupPermissionsById | undefined;
let isAdminByGroupId: any;

interface AggregateGroupPermissionsById {
  [key: number]: string[];
}

export const setUserId = (userId: string) => {
  sessionStorage.setItem(userIdKey, userId);
};

export const setUserPermissions = (entities: Entity[]) => {
  const temp: any = {};
  const isAdminTemp: any = {};

  entities.forEach((entity) => {
    let { entityId, securityGroups } = entity;
    if (entityId === undefined) {
      entityId = 0;
    }

    securityGroups.forEach((group) => {
      if (group.name === "Admin") {
        isAdminTemp[entityId] = true;
      }

      if (group.permissions !== undefined) {
        group.permissions.forEach((permission) => {
          const { feature, attribute, operation } = permission;
          const value = `${feature}-${attribute}-${operation}`;
          if (!(entityId in temp)) {
            temp[entityId] = [];
          }
          if (!temp[entityId].includes(value)) {
            temp[entityId].push(value);
          }
        });
      }
    });
  });
  userPermissionsByGroupId = temp;
  isAdminByGroupId = isAdminTemp;

  sessionStorage.setItem(keyName, JSON.stringify(userPermissionsByGroupId));
  sessionStorage.setItem(adminKeyName, JSON.stringify(isAdminByGroupId));
};

export const getAllUserPermissions = () => {
  if (userPermissionsByGroupId !== undefined) {
    return userPermissionsByGroupId;
  }

  const data = sessionStorage.getItem(keyName);
  if (data === null) return;

  userPermissionsByGroupId = JSON.parse(data);
  return userPermissionsByGroupId;
};

export const userHasPermission = (
  feature: string,
  attribute: string | string[],
  operation: Operation
) => {
  getAllUserPermissions();
  const attributes = toArray(attribute);

  if (
    userPermissionsByGroupId === undefined ||
    userPermissionsByGroupId[0] === undefined
  ) {
    return false;
  }

  if (attributes[0] === Operation.any) {
    return userPermissionsByGroupId[0].join("|").includes(feature);
  }

  if (operation === Operation.any) {
    return attributes.some(attr => userPermissionsByGroupId ? (
        userPermissionsByGroupId[0].includes(
          `${feature}-${attr}-${Operation.read}`
        ) ||
        userPermissionsByGroupId[0].includes(
          `${feature}-${attr}-${Operation.write}`
        )
      ) : false
    )
  }

  return userPermissionsByGroupId[0].includes(
    `${feature}-${attribute}-${operation}`
  );
};

export const isUserAdmin = (aggregateGroupId?: number) => {
  if (aggregateGroupId === undefined) {
    aggregateGroupId = Number(localStorage.getItem("selectedAggregateGroupId"));
  }
  if (aggregateGroupId === undefined) {
    throw new Error("Missing Aggregate Group Id");
  }

  if (isAdminByGroupId === undefined) {
    const data = sessionStorage.getItem(adminKeyName);
    if (data === null) return false;
    isAdminByGroupId = JSON.parse(data);
  }

  return isAdminByGroupId[aggregateGroupId]
    ? isAdminByGroupId[aggregateGroupId]
    : false;
};
