import {
  determineAppAccessByRoles,
  determineAppAccessByDBSecurityGroups,
} from './../../helpers/roleHelpers';
import { FeatureFlagSet, IOrchestrator, Operation } from '@nutrien/dxp-orchestrator-module';
import {
  authenticateSession,
  getCookie,
  AuthState,
  signOutUser,
  signOutOfOffice365,
  UserState,
} from '@nutrien/dxp-security-module';
import appConfig from '../../config/config';
import { registerApplication, start } from 'single-spa';
import appConfigs, { AppType } from '../../config/orchestrator';
import postal from 'postal.request-response';
import RSVP from 'rsvp';
import { authenticatedFetch as AuthenticatedFetch, configure } from '@nutrien/cxp-api-fetch-module';
import {
  userHasPermission,
  isUserAdmin,
  getAllUserPermissions,
  setUserPermissions,
} from '../../helpers/permissions';
import config from '../../config/config';
import * as configcat from 'configcat-js';
import { isObject } from '../../helpers/typeGuards';
import { FEATURE_FLAGS_STORAGE_KEY } from '../../config/constants';

interface ToggleEventData {
  open: boolean;
}

postal.configuration.promise.createDeferred = function () {
  return RSVP.defer();
};
postal.configuration.promise.getPromise = function (dfd: any) {
  return dfd.promise;
};

// Configure auth function for authenticated fetch to work in child apps
configure(appConfig.auth);

// A shared channel for triggering events across Micro FrontEnds
const channel = postal.channel('main');
let sidebarOpen = false;

// Check if object is a type of ToggleEventData
const instanceOfToggleEventData = (obj: any): obj is ToggleEventData => {
  return 'open' in obj;
};

// Monitor Channel for sidebar toggle events
channel.subscribe('sidebar.toggle', (data: any, envelope: any) => {
  if (instanceOfToggleEventData(data)) {
    sidebarOpen = data.open;
  }
});

const isSidebarOpen = () => sidebarOpen;

// Current groupId
const AGGREGATE_GROUP_ID_KEY = 'selectedAggregateGroupId';
let aggregateGroupId: number | undefined; //TODO: This will need to be set for the CXP apps to work when pulling data
const setAggregateGroupId = (groupId: number | undefined) => {
  if (groupId !== undefined) {
    aggregateGroupId = groupId;
    localStorage.setItem(AGGREGATE_GROUP_ID_KEY, groupId.toString());
  } else {
    aggregateGroupId = groupId;
    localStorage.removeItem(AGGREGATE_GROUP_ID_KEY);
  }
};

const getAggregateGroupId = () => {
  if (aggregateGroupId !== undefined) {
    return aggregateGroupId;
  }

  const temp = localStorage.getItem(AGGREGATE_GROUP_ID_KEY);
  if (temp !== null) {
    aggregateGroupId = Number(temp);
  }

  return aggregateGroupId;
};

const setInitialUrl = (url: string) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem('initial-url', url);
  }
};

const getInitialUrl = () => {
  if (window.sessionStorage && window.sessionStorage.getItem('initial-url') !== null) {
    return window.sessionStorage.getItem('initial-url');
  }
  return '';
};

// for price list app, we want to be able to redirect to the site after login
if (
  !window.location.href.toLowerCase().includes('login') &&
  !window.location.href.toLowerCase().includes('logout') &&
  window.location.href.toLowerCase().includes('pricelist')
) {
  setInitialUrl(window.location.href);
}

export class parentOrchestrator implements IOrchestrator {
  private _authRetryCount: number;
  private readonly _maxRetryCount: number = 25;
  private _authToken: any;
  private readonly SystemJS: any;
  private readonly _authGroupsCookieKey: string = 'authState-CurrentUserGroups';
  private readonly _dxpOrchestratorUserGroupName: string = 'dxp-orchestrator-user';
  private applicationsLoaded: boolean;
  private readonly routesToIgnore: string[] = ['/resetPassword', '/requestAccess', '/logout'];
  private readonly searchParamsToIgnore: string[] = ['?change=1']; // password reset
  channel = channel;
  featureFlags: FeatureFlagSet = {};
  authenticatedFetch: (
    input: RequestInfo,
    init?: (RequestInit & { authenticationPrefix?: string }) | undefined,
  ) => Promise<Response>;

  public getAggregateGroupId = getAggregateGroupId;
  public userHasPermission = userHasPermission;
  public context: 'dxp' | 'cxp' = 'dxp';

  constructor(SystemJS: any) {
    this._authRetryCount = 0;
    this.SystemJS = SystemJS;
    this.applicationsLoaded = false;
    this.authenticatedFetch = AuthenticatedFetch;

    this.preloadConfigCatFlags();
  }

  private preloadConfigCatFlags = async () => {
    const configCatClient = configcat.createClientWithManualPoll(appConfig.configCatKey);
    const attributes = this.getUserAttributes();
    const userAttributes = this.isJson(attributes.toString())
      ? JSON.parse(attributes.toString())
      : {};
    const emailAttribute =
      userAttributes.UserAttributes &&
      userAttributes.UserAttributes.filter((o: { [s: string]: unknown } | ArrayLike<unknown>) =>
        Object.values(o).includes('email'),
      );
    let email = '';

    if (emailAttribute && emailAttribute.length) {
      email = emailAttribute[0].Value;
    }

    await configCatClient.forceRefreshAsync();

    const configCatFlags = await configCatClient
      .getAllValuesAsync({
        identifier: email || '',
        email: email,
      })
      .then((flagsObj: any) => {
        return flagsObj.reduce((acc: any, flag: any) => {
          let value = flag.settingValue;

          if (this.isJson(value)) {
            value = JSON.parse(value);
          }

          return { ...acc, [flag.settingKey]: value };
        }, {});
      });

    this.featureFlags = {
      ...this.featureFlags,
      ...configCatFlags,
    };

    sessionStorage.setItem(FEATURE_FLAGS_STORAGE_KEY, JSON.stringify(this.featureFlags));
    channel.publish('featureFlags:all', this.featureFlags);
    return configCatClient.dispose();
  };

  public isAuthenticated = (): boolean => {
    return this._authToken !== undefined;
  };

  public authenticatedFetchWithGroupId = (
    input: RequestInfo,
    init?: (RequestInit & { authenticationPrefix?: string }) | undefined,
  ) => {
    if (!aggregateGroupId) {
      getAggregateGroupId();
    }

    if (aggregateGroupId) {
      if (init)
        init = {
          ...init,
          headers: {
            ...init.headers,
            'x-aggregateGroupId': aggregateGroupId.toString(),
          },
        };
      else
        init = {
          headers: {
            'x-aggregateGroupId': aggregateGroupId.toString(),
          },
        };

      let working = input.toString();
      if (working.indexOf('aggregateGroupId=') < 0) {
        working += working.indexOf('?') < 0 ? '?' : '&';
        input = working + 'aggregateGroupId=' + aggregateGroupId;
      }
    }

    return this.authenticatedFetch(input, init);
  };

  private calculateClockDrift = (iatAccessToken: any, iatIdToken: any) => {
    const date: any = new Date();
    const now = Math.floor(date / 1000);
    const iat = Math.min(iatAccessToken, iatIdToken);
    return now - iat;
  };

  private decodePayload = (jwtToken: string) => {
    const payload = jwtToken.split('.')[1];
    try {
      return JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
    } catch (err) {
      return {};
    }
  };

  private useInitialUrl = () => {
    const initialUrl = getInitialUrl();
    if (
      initialUrl !== null &&
      initialUrl !== '' &&
      window.location.href !== initialUrl &&
      !initialUrl.toLowerCase().includes('login') &&
      !initialUrl.toLowerCase().includes('logout')
    ) {
      window.history.pushState({}, '', initialUrl);
      setInitialUrl('');
      return true;
    }
    return false;
  };

  public async authenticate() {
    const urlParams = new URLSearchParams(window.location.search);
    const idToken = urlParams.get('idToken');
    const accessToken = urlParams.get('accessToken');
    const refreshToken = urlParams.get('refreshToken');

    if (idToken && accessToken && refreshToken) {
      const { appClientId } = appConfig.auth;

      const idTokenData: any = this.decodePayload(idToken);
      const accessTokenData: any = this.decodePayload(accessToken);

      localStorage.setItem(
        'CognitoIdentityServiceProvider.' + appClientId + '.LastAuthUser',
        idTokenData['cognito:username'],
      );
      localStorage.setItem(
        'CognitoIdentityServiceProvider.' +
          appClientId +
          '.' +
          idTokenData['cognito:username'] +
          '.idToken',
        idToken,
      );
      localStorage.setItem(
        'CognitoIdentityServiceProvider.' +
          appClientId +
          '.' +
          idTokenData['cognito:username'] +
          '.accessToken',
        accessToken,
      );
      localStorage.setItem(
        'CognitoIdentityServiceProvider.' +
          appClientId +
          '.' +
          idTokenData['cognito:username'] +
          '.refreshToken',
        refreshToken,
      );
      localStorage.setItem(
        'CognitoIdentityServiceProvider.' +
          appClientId +
          '.' +
          idTokenData['cognito:username'] +
          '.clockDrift',
        '' + this.calculateClockDrift(accessTokenData['iat'], idTokenData['iat']) + '',
      );
    }

    window.addEventListener('single-spa:app-change', evt => {
      let takenPaths: string[] = [...this.routesToIgnore];

      appConfigs.forEach((item: AppType) => {
        if (item.route !== '/' && item.route !== '') {
          takenPaths.push(item.route);
        }
      });
      let isTaken = true;
      const { pathname } = window.location;
      if (pathname !== '/') {
        isTaken = takenPaths.some((item: string) => pathname.startsWith(item));
      }
      if (!isTaken) {
        window.history.pushState(null, 'Nutrien - Application Dashboard', '/');
      }
      this.removeLoader();
    });
    let authState = await this.getAuthStateAndToken();
    this.registerDashboard(authState);

    if (authState === AuthState.SIGNED_IN) {
      // first time user auth check - add to groups if allowed
      // await this.setUserGroups(
      //   this.getUserAttributes(),
      //   appConfig.auth.warehouse.appCode,
      //   appConfig.auth.warehouse.url,
      //   appConfig.auth.warehouse.endpoint,
      //   appConfig.auth.warehouse.approvedUserGroups
      // );
      // first time user auth check - add to groups if allowed
      await this.setUserGroups(
        this.getUserAttributes(),
        appConfig.auth.sales.appCode,
        appConfig.auth.sales.url,
        appConfig.auth.sales.endpoint,
        appConfig.auth.sales.approvedUserGroups,
      );
      // register our applications if they are 'signedin'
      await this.registerApplications(this._authToken, this.SystemJS, this);
      if (this.useInitialUrl()) {
        setInitialUrl('');
      }
    } else if (authState === AuthState.NOT_AUTHENTICATED) {
      this.signOutAndGoToLogin();
    } else {
      if (this.useInitialUrl()) {
        setInitialUrl('');
      } else if (
        !this.routesToIgnore.includes(window.location.pathname) &&
        !this.searchParamsToIgnore.includes(window.location.search)
      ) {
        window.history.pushState(null, 'Nutrien - Application Dashboard', '/');
      }
    }
    start();
  }

  async setUserGroups(
    userAttributes: any,
    appCode: string,
    appUrl: string,
    appEndpoint: string,
    approvedUserGroups: any,
  ) {
    const attributes = JSON.parse(userAttributes);
    if (attributes) {
      let val = attributes.UserAttributes.filter(
        (o: { [s: string]: unknown } | ArrayLike<unknown>) => Object.values(o).includes('email'),
      );
      const userEmail = val[0].Value;
      let groups: any = await this.addUserToCognitoGroup(
        appCode,
        userEmail,
        attributes.Username,
        appConfig.auth.userPoolId,
        appUrl,
        appEndpoint,
        approvedUserGroups,
      );
      if (groups) {
        const currentGroups = this.getCurrentUserGroups();
        const approvedGroups = approvedUserGroups.split(',');
        approvedGroups.forEach((group: string) => {
          //remove groups if they exist
          const index = currentGroups.indexOf(group);
          if (index > -1) {
            currentGroups.splice(index, 1);
          }
        });
        groups.forEach((group: string) => {
          //add approved groups back in
          currentGroups.push(group);
        });
        await this.updateUserState(currentGroups);
      }
    }
  }

  async addUserToCognitoGroup(
    appCode: string,
    email: string,
    userName: string,
    userPoolId: string,
    appUrl: string,
    appEndpoint: string,
    cognitoGroup?: string,
  ) {
    let response = this.fetch(`${appUrl}${appEndpoint}`, {
      method: 'POST',
      body: JSON.stringify({
        appCode: appCode,
        email: email,
        userName: userName,
        cognitoGroup: cognitoGroup ? cognitoGroup : null,
        userPoolId: userPoolId,
      }),
    })
      .then((res: Response) => {
        if (res.status !== 200) return false;
        return res.json();
      })
      .catch((err: Response) => {
        return false;
      });
    return response;
  }

  setCookie(name: string, value: any, days?: number) {
    var expires = '';
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = '; expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + JSON.stringify(value) + expires + ';path=/';
  }

  async updateUserState(groups: any) {
    try {
      this.setCookie(this._authGroupsCookieKey, groups);
    } catch (err) {}
  }

  private async getAuthStateAndToken() {
    try {
      let authState = await authenticateSession(appConfig.auth);
      if (authState === AuthState.SIGNED_IN) {
        authState = AuthState.UNAUTHORIZED;
      }
      if (authState === AuthState.PROMPT_LOGIN) {
        return authState;
      }

      this._authToken = await this.getToken();

      const dbAuthorizedApps = await determineAppAccessByDBSecurityGroups(
        appConfigs,
        this.authenticatedFetch,
      );

      const authGroups = getCookie(this._authGroupsCookieKey) as string[];

      if (
        (authGroups && authGroups.includes(this._dxpOrchestratorUserGroupName)) ||
        dbAuthorizedApps.length > 0
      ) {
        authState = AuthState.SIGNED_IN;
      }
      return authState;
    } catch (err) {
      if (isObject(err) && err.code === 'UserNotFoundException') {
        let authState = AuthState.NOT_AUTHENTICATED;

        return authState;
      }
    }
  }

  public async onAuthStateChange(authState: string) {
    if (!this.applicationsLoaded) {
      authState = await this.getAuthStateAndToken();
      if (authState === AuthState.SIGNED_IN) {
        // register our applications if they are 'signedin'
        await this.registerApplications(this._authToken, this.SystemJS, this);
      }
    }
  }

  private async authenticateAndSetAuthToken() {
    let authState = await authenticateSession(appConfig.auth);
    this._authToken = await this.getToken();
    return authState;
  }

  private registerDashboard(authStatus: string) {
    registerApplication(
      'root',
      () => import('../../root.app'),
      () => true,
      {
        orchestrator: appConfigs,
        authStatus: authStatus,
        getAuthStatus: this.getAuthStateAndToken.bind(this),
        onAuthStateChange: this.onAuthStateChange.bind(this),
        signOutAndGoToLogin: this.signOutAndGoToLogin.bind(this),
      },
    );
  }

  private async registerApplications(authToken: any, SystemJS: any, orchestrator: IOrchestrator) {
    let authorizedApps: AppType[] = appConfigs.filter((app: AppType) =>
      app.permissionFeature
        ? userHasPermission(
            app.permissionFeature,
            app.permissionAttribute || Operation.any,
            Operation.any,
          )
        : app.dbRoles
        ? determineAppAccessByRoles(app.appUserGroups, app.dbRoles)
        : true,
    );

    const { featureFlags } = this;

    const containsYardLogistics = authorizedApps.filter((app: AppType) => {
      return (
        (app.appUserGroups && app.appUserGroups.includes('sap-yard-logistics')) ||
        (app.dbRoles && app.dbRoles.includes('Truck Scheduling'))
      );
    });

    const yardLogisticsAppCount = appConfigs.filter((app: AppType) => {
      const { appUserGroups = [], dbRoles = [] } = app;

      return (
        appUserGroups.includes('all') ||
        appUserGroups.includes('dxp-orchestrator-user') ||
        appUserGroups.includes('sap-yard-logistics') ||
        dbRoles.includes('Truck Scheduling')
      );
    }).length;

    if (authorizedApps.length === yardLogisticsAppCount && containsYardLogistics.length === 1) {
      return window.location.replace(
        containsYardLogistics[0].url +
          '?token=' +
          authToken.idToken +
          '&target=' +
          containsYardLogistics[0].target,
      );
    } else {
      let isAuthenticated = this._authToken !== undefined;
      const authStateAndToken = async () => {
        await this.getAuthStateAndToken();
        const token = await this.getToken();
        return token;
      };
      authorizedApps.forEach(function (item: AppType) {
        if (!item.external) {
          registerApplication(
            item.name,
            () => SystemJS.import(item.url),
            location => {
              return item.activityFunction !== undefined
                ? item.activityFunction(location, featureFlags)
                : item.name === 'dxp-dashboard-app'
                ? location.pathname === item.route
                : location.pathname.startsWith(item.route);
            },
            ((appName: string, location: Location) => {
              const baseCustomProps = {
                orchestrator,
                channel,
                appName: item.description,
                isSidebarOpen,
                setAggregateGroupId,
                getAggregateGroupId,
                getAllUserPermissions,
                setUserPermissions,
                userHasPermission,
                isUserAdmin,
                authStateAndToken,
                isAuthenticated,
                authConfig: config,
                authenticatedFetch: orchestrator.authenticatedFetch,
                context: 'dxp',
                featureFlags,
              };

              let { customProps } = item;
              if (item.customProps && typeof item.customProps === 'function') {
                customProps = item.customProps(appName, location);
              }

              return {
                ...baseCustomProps,
                appConfig: customProps,
              };
            })(config.appName, window.location),
          );
          //pre-load items marked as true from orchestrator.ts
          if (item.preload) {
            SystemJS.import(item.url);
          }
        }
      });

      this.applicationsLoaded = true;
    }
  }

  private removeLoader() {
    const loading = document.getElementById('loading');
    if (loading) {
      loading.outerHTML = '';
    }
  }

  private async getToken() {
    let values = { accessToken: '', idToken: '', refreshToken: '' } || {};
    for (var key in localStorage) {
      if (key.endsWith('idToken')) {
        values.idToken = localStorage.getItem(key) || '{}';
      }
      if (key.endsWith('accessToken')) {
        values.accessToken = localStorage.getItem(key) || '{}';
      }
      if (key.endsWith('refreshToken')) {
        values.refreshToken = localStorage.getItem(key) || '{}';
      }
    }
    return values;
  }

  public isExternalUser(): boolean {
    let isExternalUser = true;
    try {
      const attributes = this.getUserAttributes();
      const userAttributes = JSON.parse(attributes.toString());
      const emailAttribute = userAttributes.UserAttributes.filter(
        (o: { [s: string]: unknown } | ArrayLike<unknown>) => Object.values(o).includes('email'),
      );
      let email = '';

      if (emailAttribute && emailAttribute.length) {
        email = emailAttribute[0].Value;
      }
      isExternalUser = email.split('@')[1] !== 'nutrien.com';
    } catch (e) {
      console.log('User information not found, logging out user anyway');
    }
    return isExternalUser;
  }

  async signOutAndGoToLogin() {
    if (this.isAuthenticated()) {
      await signOutUser(appConfig.auth, this._authToken.refreshToken);
    }

    if (this.isExternalUser()) {
      window.location.replace('/');
    }
  }

  async signOut() {
    await signOutUser(appConfig.auth, this._authToken.refreshToken);
  }

  signOutFromMicrosoft() {
    const redirectUri = appConfig.auth.redirectSignIn;
    const encodedRedirectUri = encodeURIComponent(redirectUri);
    signOutOfOffice365(
      appConfig.auth.domain,
      appConfig.auth.userPoolWebClientId,
      encodedRedirectUri,
    );
  }

  async fetch(endpoint: string, init?: RequestInit): Promise<Response> {
    let that = this; // make a ref to 'this' for our error handler
    // add auth header token
    // extend headers
    if (init) {
      init.headers = {
        Authorization: this._authToken.idToken,
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      };
    }

    return new Promise(async (resolve, reject) => {
      fetch(endpoint, init)
        .then((response: Response) => {
          if (response.ok) {
            resolve(response);
          } else {
            if (response.status !== 401) {
              reject(response.json());
            } else {
              reject();
            }
          }
        })
        .catch(async function (error) {
          // 401 unauthorized requests fall here
          if (that._authRetryCount < that._maxRetryCount) {
            console.log(
              'retrying authentication flow (attempt #' +
                (that._authRetryCount + 1) +
                ' of ' +
                that._maxRetryCount +
                ')...',
            );
            that._authRetryCount++;

            let authState = await that.authenticateAndSetAuthToken();
            if (authState === AuthState.PROMPT_LOGIN) {
              await that.signOutAndGoToLogin();
            } else if (authState === AuthState.SIGNED_IN) {
              that._authRetryCount = 0; // successful re-auth so we reset the retry counter
              try {
                let response = await fetch(endpoint, init);
                if (response.ok) {
                  resolve(response);
                } else {
                  reject(response.json());
                }
              } catch (error) {
                reject(error);
              }
            }
          } else {
            // redirect to error page after _maxRetryCount attempts
            that._authRetryCount = 0;
            await that.signOutAndGoToLogin();
          }
        });
    });
  }

  async fetchLegacy(endpoint: string, init?: RequestInit): Promise<Response> {
    let that = this; // make a ref to 'this' for our error handler

    // add auth header token
    // extend headers
    if (init) {
      init.headers = {
        Authorization: this._authToken.idToken,
      };
    }

    return new Promise(async (resolve, reject) => {
      fetch(endpoint, init)
        .then((response: Response) => {
          if (response.ok) {
            resolve(response);
          } else {
            resolve(response);
          }
        })
        .catch(async function (error) {
          // 401 unauthorized requests fall here
          if (that._authRetryCount < that._maxRetryCount) {
            console.log(
              'retrying authentication flow (attempt #' +
                (that._authRetryCount + 1) +
                ' of ' +
                that._maxRetryCount +
                ')...',
            );
            that._authRetryCount++;

            let authState = await that.authenticateAndSetAuthToken();
            if (authState === AuthState.PROMPT_LOGIN) {
              await that.signOutAndGoToLogin();
            } else if (authState === AuthState.SIGNED_IN) {
              that._authRetryCount = 0; // successful re-auth so we reset the retry counter
            }
          } else {
            // redirect to error page after _maxRetryCount attempts
            that._authRetryCount = 0;
            await that.signOutAndGoToLogin();
          }
        });
    });
  }

  getCurrentUserGroups() {
    let currentRoles: string[] = [];
    try {
      currentRoles = JSON.parse(getCookie(UserState.AUTH_CURRENTUSER_GROUPS));
    } catch (e) {
      console.error('Cookie is not there or could not be parsed', e);
    }

    return currentRoles;
  }

  public getUserAttributes() {
    let userAttributes;
    for (var key in localStorage) {
      if (key.endsWith('userData')) {
        userAttributes = localStorage.getItem(key) || '{}';
      }
    }
    if (userAttributes) return userAttributes;
    return [];
  }

  isCurrentUserInGroup(groupName: string) {
    const currentRoles: string[] = JSON.parse(getCookie(UserState.AUTH_CURRENTUSER_GROUPS));

    if (currentRoles) return groupName in currentRoles;
    return false;
  }

  isJson = (str: string) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
}
