const env = process.env.REACT_APP_ENV;

const envAPIRootURL = (env === "prod") ? `https://api` : `https://${env}api`;

const commonWarehouseTags = {
  endpoint: "authorizeWarehouseUser",
  appCode: "dxp-warehouse",
  approvedUserGroups:
      "dxpw-admin,Warehouse Administrator,dxpw-standard,Warehouse Operator,dxpw-standard-read-only,Warehouse OperatorReadonly,Warehouse OperatorCanReceive,dxpw-coordinator,Warehouse Coordinator,dxpw-coordinator-read-only,Warehouse CoordinatorReadonly"
}

const commonSalesTag = {
  endpoint: "authorizeUser",
  appCode: "dxp-salesContract",
  approvedUserGroups:
      "dxp-salesContract-user,dxp-salesManager-group,dxp-salesDirector-group"
}

const commonRailcarTag = {
  endpoint: "authorizeUser",
  appCode: (env === "prod") ? `dxp-railfleet-management` : `dxp-salesContract`,
  approvedUserGroups: "dxp-rfm-admin,dxp-rfm-analyst,dxp-rfm-viewer"
}

const commonConfig = {
  awsRegion: "us-east-2",
  cognitoRegion: "us-east-2",
  appUserGroupName: "dxp-orchestrator-user",
  scope: [
    "phone",
    "email",
    "profile",
    "openid",
    "aws.cognito.signin.user.admin"
  ],
  loglevel: "DEBUG",
  authEndpoint: "authorizeWarehouseUser",
  cookieExpiration: 7,
  redirectSignIn: `${window.location.protocol}//${window.location.host}/`,
  redirectSignOut: `${window.location.protocol}//${window.location.host}/`,
    warehouse: {
      url: `${envAPIRootURL}.nx.nutrien.com/warehouse/`,
      ...commonWarehouseTags
    },
    sales: {
      url: `${envAPIRootURL}.nx.nutrien.com/sales/`,
      ...commonSalesTag
    },
    railcar: {
      url: `${envAPIRootURL}.nx.nutrien.com/railfleetmanagement/`,
      ...commonRailcarTag
    }
};

/* Applied only to local environment */
const local = {
  auth: {
    ...commonConfig,
    userPoolWebClientId: "4aiokj82e5js1n0gkceubct4h9",
    domain: `dev-experience-platform-nutrien.auth.us-east-2.amazoncognito.com`,
    redirectSignIn: "http://localhost:3000/",
    redirectSignOut: "http://localhost:3000/",
    userPoolId: "us-east-2_dP8bBMlb6",
    appClientId: "4aiokj82e5js1n0gkceubct4h9",
    warehouse: {
      url: `https://devapi.nx.nutrien.com/warehouse/`,
      ...commonWarehouseTags,
    },
    sales: {
      url: `https://devapi.nx.nutrien.com/sales/`,
      ...commonSalesTag
    },
    railcar: {
      url: `https://devapi.nx.nutrien.com/railfleetmanagement/`,
      ...commonRailcarTag
    }
  },
  requestAccessConfig: {
    rootUrl: "https://devapi.nx.nutrien.com/user-management",
    apiKey: "DdJliiq4Kca5rinPDccti7lC7SjYuOQG51oUmY5a"
  },
  cxpAPIUrl: "https://devapi.nutrienexperience.com",
  dxpAPIUrl: "https://devapi.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/Vik7LXNTeEyrVgLtOQuhlg",
  googleAnalyticsID: "UA-141922109-1"
};

const dev = {
  auth: {
    ...commonConfig,
    userPoolWebClientId: "4aiokj82e5js1n0gkceubct4h9",
    domain: `dev-experience-platform-nutrien.auth.us-east-2.amazoncognito.com`,
    userPoolId: "us-east-2_dP8bBMlb6",
    appClientId: "4aiokj82e5js1n0gkceubct4h9",
  },
  requestAccessConfig: {
    rootUrl: `${envAPIRootURL}.nx.nutrien.com/user-management`,
    apiKey: "DdJliiq4Kca5rinPDccti7lC7SjYuOQG51oUmY5a"
  },
  cxpAPIUrl: `${envAPIRootURL}.nutrienexperience.com`,
  dxpAPIUrl: `${envAPIRootURL}.nx.nutrien.com/railfleetmanagement`,
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/Vik7LXNTeEyrVgLtOQuhlg",
  googleAnalyticsID: "UA-141922109-1"
};

const staging = {
  auth: {
    ...commonConfig,
    userPoolWebClientId: "1p1h21bq988pcui1l1b37ejcc5",
    domain: "stage-experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
    redirectSignIn: "https://stage.nx.nutrien.com/",
    redirectSignOut: "https://stage.nx.nutrien.com/",
    userPoolId: "us-east-2_l685tImU4",
    appClientId: "1p1h21bq988pcui1l1b37ejcc5",
    warehouse: {
      url: "https://stagingapi.nx.nutrien.com/warehouse/",
      ...commonWarehouseTags
    },
    sales: {
      url: "https://stagingapi.nx.nutrien.com/sales/",
      ...commonSalesTag
    },
    railcar: {
      url: "https://stagingapi.nx.nutrien.com/railfleetmanagement/",
      ...commonRailcarTag
    }
  },
  requestAccessConfig: {
    rootUrl: "https://stagingapi.nx.nutrien.com/user-management",
    apiKey: "GIiit2k6e64H4ro75qU7f26kFFeWuIVB7OS69h6B"
  },
  cxpAPIUrl: "https://stagingapi.nutrienexperience.com",
  dxpAPIUrl: "https://stagingapi.nx.nutrien.com/railfleetmanagement",
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/SH3LOEzDj0i1jK4ymY88yQ",
  googleAnalyticsID: "UA-141922109-12"
};

const uat = {
  auth: {
    ...commonConfig,
    userPoolWebClientId: "1e1a6q1qrp20oms8psmg5q39ec",
    domain:
        "preprod-experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
    userPoolId: "us-east-2_cupM6yNkc",
    appClientId: "1e1a6q1qrp20oms8psmg5q39ec",
  },
  requestAccessConfig: {
    rootUrl: `${envAPIRootURL}.nx.nutrien.com/user-management`,
    apiKey: "fiPUpqmTrMrX6nIJxrzi46EQnJmbmR59dFcwcp6j"
  },
  cxpAPIUrl: `${envAPIRootURL}.nutrienexperience.com`,
  dxpAPIUrl: `${envAPIRootURL}.nx.nutrien.com/railfleetmanagement`,
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/mX8yj-6s6USoxs-b1gbe-Q",
  googleAnalyticsID: "UA-141922109-12"
};

const prod = {
  auth: {
    ...commonConfig,
    userPoolWebClientId: "2m4kp84vvb0filot0o12hqtn0",
    domain: "experience-platform-nutrien.auth.us-east-2.amazoncognito.com",
    userPoolId: "us-east-2_XEaLQx09U",
    appClientId: "2m4kp84vvb0filot0o12hqtn0",
  },
  requestAccessConfig: {
    rootUrl: `${envAPIRootURL}.nx.nutrien.com/user-management`,
    apiKey: "vpwkQqocA19RRYm3FTqj35KX7Gguelf48Nu3GEvj"
  },
  cxpAPIUrl: `${envAPIRootURL}.nutrienexperience.com`,
  dxpAPIUrl: `${envAPIRootURL}.nx.nutrien.com/railfleetmanagement`,
  configCatKey: "D-HZCLI1-0qLkf5cLucb8Q/PItORgInq0mEWOK41VCKtA",
  googleAnalyticsID: "UA-141922109-13"
};

interface IConfig {
  [key: string]: any;
  "local": any;
  "dev": any;
  "dev-blue": any;
  "dev-green": any;
  "staging": any;
  "uat": any;
  "uat-blue": any;
  "uat-green": any;
  "prod": any;
  "prod-blue": any;
  "prod-green": any;
}

let config: IConfig = {
  env: env,
  "local": local,
  "dev": dev,
  "dev-blue": dev,
  "dev-green": dev,
  "staging": staging,
  "uat": uat,
  "uat-blue": uat,
  "uat-green": uat,
  "prod": prod,
  "prod-blue": prod,
  "prod-green": prod,
};

export default config[config.env ? config.env : "local"];
