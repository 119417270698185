import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import appConfig from "./config/config";
import { configure } from "@nutrien/dxp-security-module";
import { parentOrchestrator } from "./models/orchestrator/parentOrchestrator";
import * as serviceWorker from "./service-worker";

declare const SystemJS: any;
export let orchestrator: parentOrchestrator;
export async function Authenticate() {
  configure(appConfig.auth);
  setTimeout(async () => {
    orchestrator = new parentOrchestrator(SystemJS);
    await orchestrator.authenticate();
  }, 1000);
}
export default Authenticate().catch(alert);
serviceWorker.register();
