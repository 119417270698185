import { getCookie, UserState } from "@nutrien/dxp-security-module";
import { AppType } from "../config/orchestrator";
import appConfig from "../config/config";
import { setUserId, setUserPermissions } from "./permissions";

export const determineAppAccessByRoles = (
  appUserGroups: string[] | undefined,
  dbRoles: string[] | undefined
): boolean => {
  if (!appUserGroups && !dbRoles) return false;

  let permittedRoles: string | undefined;
  let groupRoles = [];

  // get dbRoles from session
  let currentDbRoles = sessionStorage.getItem("DBAdminRoles");
  if (currentDbRoles) {
    groupRoles = JSON.parse(currentDbRoles);
  }

  // get cognito groups from cookie (legacy)
  const currentUserGroups = getCookie(UserState.AUTH_CURRENTUSER_GROUPS);
  const currentRoles =
    currentUserGroups !== "undefined" ? JSON.parse(currentUserGroups) : ["all"];

  // check dbRoles from permissions endpoint
  if (dbRoles) {
    permittedRoles = groupRoles.find((userGroup: string) =>
      dbRoles.includes(userGroup)
    );
  }
  // fallback to cognito user groups (legacy)
  if (appUserGroups && !permittedRoles) {
    permittedRoles = currentRoles.find(
      (userGroup: string) =>
        appUserGroups.includes(userGroup) || appUserGroups.includes("all")
    );
  }
  return !!permittedRoles;
};

/**
 * Takes in a array of AppType and filters them based on the permissions the user has on the DB in Aurora.
 *
 * @param {AppType[]} childApplications
 * @param {((
 *     input: RequestInfo,
 *     init?: RequestInit | undefined
 *   ) => Promise<Response>)} authenticatedFetch
 */
export const determineAppAccessByDBSecurityGroups = async (
  childApplications: AppType[],
  authenticatedFetch: (
    input: RequestInfo,
    init?: RequestInit | undefined
  ) => Promise<Response>
) => {
  // Get permissions from API
  let groupRoles = [];
  try {
    let permissions = await authenticatedFetch(
      `${appConfig.cxpAPIUrl}/security/permissions`,
      { method: "GET" }
    );
    let json = await permissions.json();
    setUserId(json.access.userId);
    groupRoles = json.access.entities;
  } catch (error) {
    console.error("Something went wrong with CXP permissions", error);
  }

  try {
    let permissionsDxp = await authenticatedFetch(
      `${appConfig.dxpAPIUrl}/permissions`,
      { method: "GET" }
    );
    let dxpJson = await permissionsDxp.json();
    groupRoles = groupRoles.concat(dxpJson.access.entities);
  } catch (error) {
    console.error("Something went wrong with DXP permissions", error);
  }

  //Check applications for permissions
  const adminRoles: string[] = [];

  groupRoles.forEach((groupRole: any) => {
    if (!("entityId" in groupRole)) {
      groupRole.securityGroups.forEach((securityGroups: any) => {
        adminRoles.push(securityGroups.name);
      });
    }
  });

  let sessionAdminRoles = sessionStorage.getItem("DBAdminRoles");
  if (sessionAdminRoles !== JSON.stringify(adminRoles.sort())) {
    setUserPermissions(groupRoles);
    sessionStorage.setItem("DBAdminRoles", JSON.stringify(adminRoles.sort()));
  }
  return getAuthorizedApps(childApplications, adminRoles);
};

const getAuthorizedApps = (childApplications: AppType[], adminRoles: any[]) => {
  //Return filtered list of authorized apps based on permissions
  const allowedApps: AppType[] = [];
  childApplications.forEach((childApp) => {
    if (childApp.dbRoles !== undefined) {
      if (childApp.dbRoles.filter((x) => adminRoles.includes(x)).length > 0) {
        allowedApps.push(childApp);
      }
    }
  });
  return allowedApps;
};
