import { AppConfig } from '@nutrien/dxp-orchestrator-module';
import { getAppFullRoute, getAppRootRoute } from '../helpers/routesHelpers';

export interface AppTypeBase {
  name: string;
  description: string;
  url: string;
  external: boolean;
  appUserGroups?: string[];
  route: string;
  preload: boolean;
  activityFunction?: (location: Location, featureFlags?: any) => boolean;
  target?: string;
  customProps?: AppConfig | ((appName: string, location: Location) => AppConfig);
  permissionFeature?: string;
  permissionAttribute?: string | string[];
}

type AppTypeConditional =
  | {
      permissionFeature: string;
      permissionAttribute?: string | string[];
      dbRoles?: never;
    }
  | {
      permissionFeature?: never;
      permissionAttribute?: never;
      dbRoles?: string[];
    };

export type AppType = AppTypeBase & AppTypeConditional;

const authPaths = [
  'login',
  'logout',
  'forgotUsername',
  'forgotPassword',
  'resetPassword',
  'requestAccess',
];

const toolbarPaths = [
  'railcartracking',
  'orders',
  'purchases',
  'targets',
  'invoices',
  'truckScheduleCalendar',
  'selfServe',
];

const toolbarNestedPaths = ['warehouse/receiveInventory'];

export const applicationConfigs: AppType[] = [
  {
    name: 'nx-price-list-app',
    description: 'Price List',
    url: '@nutrien/nx-price-list-app',
    external: false,
    dbRoles: ['Price List Creator', 'Price List Viewer', 'Price List User Admin'],
    route: '/pricelist',
    preload: false,
  },
  {
    name: 'dxp-sales-reporting-app',
    description: 'Sales Reporting',
    url: '@nutrien/dxp-sales-reporting-app',
    external: false,
    dbRoles: ['Sales Manager'],
    route: '/slsReporting',
    preload: false,
  },
  {
    name: 'dxp-sales-app',
    description: 'Sales App',
    url: '@nutrien/dxp-sales-app',
    external: false,
    appUserGroups: ['dxp-salesManager-group', 'dxp-salesDirector-group'],
    route: '/sales',
    preload: true,
  },
  {
    name: 'dxp-warehouse-app',
    description: 'Warehouse App',
    url: '@nutrien/dxp-warehouse-app',
    external: false,
    appUserGroups: [
      'dxpw-admin',
      'dxpw-standard',
      'dxpw-standard-read-only',
      'dxpw-coordinator',
      'dxpw-coordinator-read-only',
    ],
    dbRoles: [
      'Warehouse Administrator',
      'Warehouse Operator',
      'Warehouse OperatorCanReceive',
      'Warehouse OperatorReadonly',
      'Warehouse Coordinator',
      'Warehouse CoordinatorReadonly',
      'Warehouse Product Manager',
    ],
    route: '/warehouse',
    activityFunction: location => {
      const fullPath = getAppFullRoute(location.pathname);
      const migratedPathnames = [
        '/warehouse/inventory',
        '/warehouse/reports',
        '/warehouse/sources/list/inventoryTransactions',
        '/warehouse/receiving',
        '/warehouse/receiveInventory',
      ];
      return (
        !toolbarNestedPaths.some(path => fullPath.startsWith(path)) &&
        location.pathname.startsWith('/warehouse') &&
        !migratedPathnames.includes(location.pathname)
      );
    },
    preload: true,
  },
  {
    name: 'nx-report-physical-inventory-app',
    description: 'Report Physical Inventory App',
    url: '@nutrien/nx-warehouse-physical-inventory-app',
    external: false,
    appUserGroups: [
      'dxpw-admin',
      'dxpw-standard',
      'dxpw-standard-read-only',
      'dxpw-coordinator',
      'dxpw-coordinator-read-only',
    ],
    dbRoles: [
      'Warehouse Administrator',
      'Warehouse Operator',
      'Warehouse OperatorCanReceive',
      'Warehouse OperatorReadonly',
      'Warehouse Coordinator',
      'Warehouse CoordinatorReadonly',
      'Warehouse Product Manager',
    ],
    route: '/warehouse/inventory',
    preload: false,
  },
  {
    name: 'nx-view-inventory-app',
    description: 'View Inventory Reports App',
    url: '@nutrien/nx-warehouse-view-inventory-app',
    external: false,
    appUserGroups: [
      'dxpw-admin',
      'dxpw-standard',
      'dxpw-standard-read-only',
      'dxpw-coordinator',
      'dxpw-coordinator-read-only',
    ],
    dbRoles: [
      'Warehouse Administrator',
      'Warehouse Operator',
      'Warehouse OperatorCanReceive',
      'Warehouse OperatorReadonly',
      'Warehouse Coordinator',
      'Warehouse CoordinatorReadonly',
      'Warehouse Product Manager',
    ],
    route: '/warehouse/reports',
    preload: false,
  },
  {
    name: 'nx-inventory-transaction-summary-app',
    description: 'Inventory Transaction Summary App',
    url: '@nutrien/nx-warehouse-inv-transactions-app',
    external: false,
    appUserGroups: [
      'dxpw-admin',
      'dxpw-standard',
      'dxpw-standard-read-only',
      'dxpw-coordinator',
      'dxpw-coordinator-read-only',
    ],
    dbRoles: [
      'Warehouse Administrator',
      'Warehouse Operator',
      'Warehouse OperatorCanReceive',
      'Warehouse OperatorReadonly',
      'Warehouse Coordinator',
      'Warehouse CoordinatorReadonly',
    ],
    route: '/warehouse/sources/list/inventoryTransactions',
    preload: false,
  },
  {
    name: 'nx-receive-inventory-app',
    description: 'Receive Inventory App',
    url: '@nutrien/nx-warehouse-receive-inventory-app',
    external: false,
    appUserGroups: [
      'dxpw-admin',
      'dxpw-standard',
      'dxpw-standard-read-only',
      'dxpw-coordinator',
      'dxpw-coordinator-read-only',
    ],
    dbRoles: [
      'Warehouse Administrator',
      'Warehouse Operator',
      'Warehouse OperatorCanReceive',
      'Warehouse OperatorReadonly',
      'Warehouse Receive Inventory',
      'Warehouse Coordinator',
      'Warehouse CoordinatorReadonly',
    ],
    route: '/warehouse/receiving',
    preload: false,
  },
  {
    name: 'nx-receive-inventory-app-v2',
    description: 'Receive Inventory App',
    url: '@nutrien/nx-railcar-tracking-app',
    external: false,
    appUserGroups: [
      'dxpw-admin',
      'dxpw-standard',
      'dxpw-standard-read-only',
      'dxpw-coordinator',
      'dxpw-coordinator-read-only',
    ],
    dbRoles: [
      'Warehouse Administrator',
      'Warehouse Operator',
      'Warehouse OperatorCanReceive',
      'Warehouse OperatorReadonly',
      'Warehouse Receive Inventory',
      'Warehouse Coordinator',
      'Warehouse CoordinatorReadonly',
    ],
    route: '/warehouse/receiveInventory',
    preload: false,
    customProps: { appMode: 'receiveInventory', root: 'warehouse/receiveInventory' },
  },
  {
    name: 'railfleet-management-app',
    description: 'RailFleet Management App',
    url: '@nutrien/railfleet-management-app',
    external: false,
    dbRoles: ['Administrator', 'Analyst', 'Viewer'],
    route: '/railcar',
    preload: false,
  },
  {
    name: 'nx-trex-railfleet-management-app',
    description: 'Nx Trex RailFleet Management App',
    url: '@nutrien/nx-trex-railfleet-management-app',
    external: false,
    dbRoles: ['Rail Administrator'],
    route: '/trex/users',
    preload: false,
  },
  {
    name: 'nx-trex-railcar-eta-app',
    description: 'TrEx Railcar ETA App',
    url: '@nutrien/nx-trex-railcar-eta-app',
    external: false,
    dbRoles: ['TrEx - Rail (Full Access)', 'TrEx - Rail (Read-Only)'],
    route: '/trex-eta',
    preload: false,
  },
  {
    name: 'dxp-netback-calculator-app',
    description: 'Netback Calculator',
    url: '@nutrien/dxp-netback-calculator-app',
    external: false,
    appUserGroups: ['dxp-netbackCalculator-group'],
    route: '/netbackCalculator',
    preload: false,
  },
  {
    name: 'sap-yard-logistics',
    description: 'Yard Logistics',
    url: 'https://stage-scheduling.nutrien.com:4437/nutriensso/ssologin',
    external: true,
    appUserGroups: ['sap-yard-logistics'],
    dbRoles: ['Truck Scheduling'],
    route: '',
    preload: false,
    target: '/sap/bc/ui5_ui5/sap/zdas_carrier/index.html',
  },
  {
    name: 'dxp-user-management-app',
    description: 'User Request Management',
    url: '@nutrien/dxp-user-management-app',
    external: false,
    appUserGroups: ['dxp-user-registration-approver', 'dxp-user-registration-creator'],
    route: '/usermanagement',
    preload: false,
  },
  {
    name: 'cxp-user-management',
    description: 'Customer Management',
    url: '@nutrien/cxp-user-management-app',
    external: false,
    dbRoles: ['Sales Manager', 'Customer Experience Rep'],
    route: '/customermanagement',
    preload: false,
  },
  {
    name: 'dxp-notifications-app',
    description: 'DXP Notifications App',
    url: '@nutrien/dxp-notifications-app',
    external: false,
    appUserGroups: ['all'],
    route: '',
    activityFunction: (location: Location) => {
      return (
        !authPaths.includes(location.pathname.substring(1))
      );
    },
    preload: false,
  },
  {
    name: 'dxp-sidebar-app',
    description: 'DXP Sidebar App',
    url: '@nutrien/dxp-sidebar-app',
    external: false,
    appUserGroups: ['all'],
    route: '',
    activityFunction: (location: Location) => {
      return (
        !authPaths.includes(location.pathname.substring(1))
      );
    },
    preload: false,
  },
  {
    name: 'dxp-dashboard-app',
    description: 'DXP Dashboard App',
    url: '@nutrien/dxp-dashboard-app',
    external: false,
    appUserGroups: ['all'],
    route: '/',
    preload: false,
  },
  {
    name: 'dxp-toolbar-app',
    description: 'DXP Toolbar App',
    url: '@nutrien/dxp-toolbar-app',
    external: false,
    appUserGroups: ['all'],
    route: '',
    activityFunction: (location: Location) => {
      const appName = getAppRootRoute(location.pathname);
      const fullPath = getAppFullRoute(location.pathname);
      return (
        toolbarPaths.includes(appName) || toolbarNestedPaths.some(path => fullPath.startsWith(path))
      );
    },
    preload: false,
  },
  {
    name: 'dxp-demurrage-app',
    description: 'DXP Demurrage App',
    url: '@nutrien/dxp-demurrage-app',
    external: false,
    appUserGroups: ['dxp-salesManager-group'],
    route: '/demurrage',
    preload: false,
  },

  {
    name: 'nx-railcar-tracking-app',
    description: 'Railcar Tracking',
    url: '@nutrien/nx-railcar-tracking-app',
    external: false,
    dbRoles: [
      'Sales Manager',
      'Sales Director',
      'Customer Experience Rep',
      'Credit/Accounting Manager',
    ],
    route: '/railcartracking',
    preload: false,
    customProps: { appMode: 'vehicleTracking', root: 'railcartracking' },
  },
  {
    name: 'dxp-ibp-app',
    description: 'SAP IBP Combination',
    url: '@nutrien/dxp-ibp-app',
    external: false,
    appUserGroups: ['dxp-salesManager-group', 'dxp-ibp-user'],
    route: '/ibp',
    preload: false,
  },
  {
    name: 'dxp-orders-app',
    description: 'Orders & Shipments',
    url: '@nutrien/dxp-orders-app',
    external: false,
    dbRoles: [
      'Sales Manager',
      'Sales Director',
      'Customer Experience Rep',
      'Credit/Accounting Manager',
    ],
    route: '/orders',
    preload: false,
  },
  {
    name: 'dxp-purchases-app',
    description: 'Purchases',
    url: '@nutrien/dxp-purchases-app',
    external: false,
    dbRoles: ['Sales Manager', 'Customer Experience Rep', 'Credit/Accounting Manager'],
    route: '/purchases',
    preload: false,
  },
  {
    name: 'dxp-download-manager-app',
    description: 'DXP Download Manager App',
    url: '@nutrien/dxp-download-manager-app',
    external: false,
    appUserGroups: ['all'],
    route: '',
    activityFunction: (location: Location) => {
      return (
        !authPaths.includes(location.pathname.substring(1))
      );
    },
    preload: false,
  },
  {
    name: 'dxp-targets-app',
    description: 'Targets',
    url: '@nutrien/dxp-targets-app',
    external: false,
    dbRoles: ['Sales Manager'],
    route: '/targets',
    preload: false,
  },
  {
    name: 'dxp-account-status-app',
    description: 'Account Status & Invoices',
    url: '@nutrien/dxp-account-status-app',
    external: false,
    dbRoles: ['Sales Manager', 'Customer Experience Rep', 'Credit/Accounting Manager'],
    route: '/invoices',
    preload: false,
  },
  {
    name: 'nx-truck-schedule-calendar-app',
    description: 'Truck Schedule',
    url: '@nutrien/nx-truck-schedule-calendar-app',
    external: false,
    dbRoles: ['Sales Manager', 'Customer Experience Rep'],
    route: '/truckScheduleCalendar',
    preload: false,
  },
  {
    name: 'nx-loading-hours-app',
    description: 'Loading Hours',
    url: '@nutrien/nx-loading-hours-app',
    external: false,
    appUserGroups: ['all'],
    dbRoles: ['Loading Hours Admin', 'Loading Hours Support'],
    route: '/loadingHours',
    preload: false,
  },
  {
    name: 'dxp-mss-orders-app',
    description: 'MSS Orders',
    url: '@nutrien/dxp-mss-orders-app',
    external: false,
    appUserGroups: ['dxp-salesManager-group'],
    dbRoles: ['Sales Manager'],
    route: '/mssOrders',
    preload: false,
  },
  {
    name: 'marine-management-app',
    description: 'Marine Management App',
    url: '@nutrien/dxp-marine-management-app',
    external: false,
    dbRoles: ['Marine Admin', 'Marine Display'],
    route: '/marine',
    preload: false,
  },
  {
    name: 'nx-self-serve-app',
    description: 'Self-Serve',
    url: '@nutrien/nx-self-serve-app',
    external: false,
    dbRoles: ['Sales Manager', 'Customer Experience Rep', 'Restriction Admin'],
    route: '/selfServe',
    activityFunction: location => {
      return location.pathname === '/selfServe';
    },
    preload: false,
  },
  {
    name: 'dxp-self-serve-management-app',
    description: 'Self-Serve Restrictions',
    url: '@nutrien/nx-self-serve-management-app',
    external: false,
    permissionFeature: 'Order Entry',
    permissionAttribute: ['Restriction View', 'Restriction Management'],
    route: '/self-serve-restrictions',
    preload: false,
  },
  {
    name: 'dxp-inventory-reporting-app',
    description: 'Inventory Reporting',
    url: '@nutrien/dxp-inventory-reporting-app',
    external: false,
    dbRoles: ['Sales Manager', 'Sales Director', 'Sales Analyst', 'Customer Experience Rep'],
    route: '/inventoryReporting',
    preload: false,
  },
  {
    name: 'nx-mss-contracts-app',
    description: 'MSS Contracts',
    url: '@nutrien/nx-mss-contracts-app',
    external: false,
    dbRoles: ['Sales Manager', 'Sales Director', 'Sales Analyst', 'Customer Experience Rep'],
    appUserGroups: ['dxp-salesManager-group', 'dxp-salesDirector-group'],
    route: '/mss-contracts',
    preload: false,
  },
];

export default applicationConfigs;
