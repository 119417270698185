import { NotificationBarDetails } from "../models/NotificationBarDetails";

export const isObject = (value: unknown): value is Record<string, unknown> => {
  return typeof value === "object" && value !== null && !Array.isArray(value);
}

export const isBoolean = (value: unknown): value is boolean => {
  return typeof value === "boolean";
}

export const isString = (value: unknown): value is string => {
  return typeof value === "string";
}

export const isStringAndNotEmpty = (value: unknown): value is string => {
  return isString(value) && Boolean(value);
}

export const isValidNotificationDetail = (data: unknown): data is NotificationBarDetails => {
  return isObject(data) &&
    isStringAndNotEmpty(data.barText) &&
    isStringAndNotEmpty(data.modalTitle) &&
    isString(data.time) &&
    isString(data.date) &&
    isString(data.timeText) &&
    isStringAndNotEmpty(data.modalText) &&
    isStringAndNotEmpty(data.buttonText) &&
    (!data.green || isBoolean(data.green)) &&
    (!data.active || isBoolean(data.active)) &&
    (!data.buttonUrl || isString(data.buttonUrl)) &&
    (!data.button2Text || isString(data.button2Text)) &&
    (!data.button2Url || isString(data.button2Url));
}
